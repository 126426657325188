import React from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import Footer from "../../components/footer/Footer";
import Certifications from "../../containers/certifications/Certifications";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assests/images/android-icon-144x144.png";

function Home(props) {
  return (
    <div>
      <Header theme={props.theme} setTheme={props.setTheme} />
      <Greeting theme={props.theme} />
      <Certifications theme={props.theme} />
      <Footer theme={props.theme} />
      <FloatingWhatsApp
        phoneNumber="+5493413631049"
        accountName="RevalSoft"
        avatar={logo}
        allowEsc
        allowClickAway
        statusMessage="Disponible"
        chatMessage="¿Cómo puedo ayudarte?"
      />
    </div>
  );
}

export default Home;
